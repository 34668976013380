"use client"
import {Label, type LabelProps} from "@headlessui/react"
import {forwardRef} from "react"
import {twMerge} from "tailwind-merge"

const StyledLabel = forwardRef<HTMLLabelElement, LabelProps>(({children, className, ...rest}, ref) => {
  return (
    <Label
      className={(b) =>
        twMerge("text-sm font-semibold text-gray-500", typeof className === "string" ? className : className?.(b))
      }
      {...rest}
      ref={ref}
    >
      {children}
    </Label>
  )
})
export default StyledLabel

StyledLabel.displayName = "StyledLabel"
