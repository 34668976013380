"use client"
import {Field, type FieldProps} from "@headlessui/react"
import {type VariantProps, cva} from "cva"
import {twMerge} from "tailwind-merge"

const fieldWrapper = cva({
  base: "flex",
  variants: {
    flow: {
      col: "flex-col gap-1.5",
      row: "items-start gap-8 py-5",
    },
  },
})

const StyledField = ({
  children,
  className,
  flow,
  ...props
}: FieldProps & Required<VariantProps<typeof fieldWrapper>>) => {
  return (
    <Field
      {...props}
      className={(b) => twMerge(fieldWrapper({flow}), typeof className === "string" ? className : className?.(b))}
    >
      {children}
    </Field>
  )
}

export default StyledField
